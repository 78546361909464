@import url(https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&family=Raleway:wght@400;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  box-sizing: border-box;
}

body {
  background: #282828;
  color: white;
}

.headerContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.logo {
  height: 80px;
}

.cartBtn {
  margin: 0 5px;
}

.userBtn {
  margin: 0 5px;
}

.loginBtn {
  margin: 0 5px;
}

.logoutBtn {
  margin: 0 5px;
}

.userBtns {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 30px 5px 20px 5px;
}

.authContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.authContainer h2 {
  font-family: "PT Serif";
  font-size: 1.5em;
  margin-bottom: 10px;
  margin-top: 0;
}
.authContainer p {
  font-family: "Raleway";
}
.authContainer label {
  font-family: "Raleway";
}
.authContainer input {
  margin: 5px 0;
  width: 100%;
}

.box {
  text-align: left;
  width: 80%;
  margin: 30px auto;
  padding: 20px 40px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
}
.box Button {
  margin-top: 10px;
  margin-left: 0;
}

@media (min-width: 800px) {
  .authContainer {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .box {
    width: 350px;
    margin: 30px;
  }
}
.admin h1 {
  font-family: "PT Serif";
  padding-top: 30px;
}
.admin h2 {
  font-family: "Raleway";
  text-align: center;
  padding-top: 5px;
  font-size: 1.5rem;
}
.admin p {
  font-family: "Raleway";
  line-height: 1.4;
}
.admin input {
  width: 100%;
  margin: 5px 0;
}

.addBox {
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 40px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
}

.adminBox {
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 40px;
  overflow: auto;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
}
.adminBox .adminFlex {
  display: -webkit-flex;
  display: flex;
}
.adminBox .adminFlex .adminImg {
  height: 200px;
  object-fit: contain;
  margin-right: 5px;
}
.adminBox input {
  width: 100%;
  margin: 5px 0;
}

@media (max-width: 480px) {
  .adminImg {
    display: none;
  }
}
.products {
  text-align: center;
  margin-top: 20px;
}
.products h1 {
  font-family: "PT Serif";
  text-align: center;
  font-size: 2rem;
  margin: 0 15px;
}

.productFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.productBox img {
  display: block;
  height: 350px;
  object-fit: cover;
  margin: 0 auto;
  cursor: pointer;
}

@media (min-width: 480px) {
  .productFlex {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin: 0;
  }
}
@media (min-width: 800px) {
  .productBox img {
    transition: 0.5s;
    -webkit-filter: brightness(60%) grayscale(50%);
            filter: brightness(60%) grayscale(50%);
  }
  .productBox img:hover {
    -webkit-filter: brightness(100%) grayscale(0%);
            filter: brightness(100%) grayscale(0%);
  }
}
.productDisplay {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}
.productDisplay img {
  display: block;
  width: 325px;
  margin: 0 auto;
  object-fit: cover;
}
.productDisplay .infoDisplay {
  text-align: center;
  margin: 0 20px;
  line-height: 1.4rem;
}
.productDisplay .infoDisplay h1 {
  font-family: "PT Serif";
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-bottom: 8px;
  box-shadow: 0px 10px 13px -10px #000000, 0px 28px 0px 26px rgba(0, 0, 0, 0);
}
.productDisplay .infoDisplay p {
  font-family: "Raleway";
  margin-bottom: 5px;
}

.doesOwnButton {
  display: inline-block;
  background: none;
  color: white;
  font-family: Tahoma;
  letter-spacing: 1px;
  border: 1px solid white;
  border-radius: 4px;
  padding: 10px;
  margin: 0 10px;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.3);
}

.images {
  margin: 30px auto;
}
.images img {
  width: 90vw;
}

@media (min-width: 480px) {
  .images img {
    width: 80vw;
  }
}
@media (min-width: 800px) {
  .images img {
    width: 50vw;
  }
}
.filter {
  margin: 20px;
  display: -webkit-flex;
  display: flex;
}

.searchBar {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 20px 45px 10px 45px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
}
.searchBar input {
  margin-bottom: 20px;
  margin-right: 11px;
}

@media (max-width: 480px) {
  .searchBar {
    width: 68vw;
    padding: 20px 45px 10px 45px;
  }
  .searchBar input {
    margin-right: 0;
  }
}
h1 {
  text-align: center;
  font-size: 2rem;
  font-family: "PT Serif";
  padding: 10px;
  margin-bottom: 15px;
}

.flexUserBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

@media (min-width: 800px) {
  .flexUserBox {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.userBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
  margin: 30px;
  padding: 5px;
}
.userBox img {
  height: 250px;
  object-fit: contain;
}

.textBox {
  text-align: center;
  padding-bottom: 10px;
}
.textBox h2 {
  font-size: 1.5rem;
  margin: 17px 0;
  font-family: "PT Serif";
  line-height: 1.4;
}
.textBox p {
  margin: 10px;
  font-family: "Raleway";
  line-height: 1.4;
}
.textBox button {
  margin: 5px 45px;
}

@media (min-width: 480px) {
  .userBox {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media (min-width: 800px) {
  .userBox {
    width: 450px;
  }
  .userBox img {
    height: 300px;
  }

  .textBox {
    margin-top: 15px;
  }
}
.cart {
  text-align: center;
}
.cart h1 {
  font-family: "PT Serif";
  font-size: 1.5rem;
  padding: 15px;
}
.cart h3 {
  font-family: "Raleway";
  font-size: 1.2rem;
  padding: 20px;
}

.flexCartBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (min-width: 800px) {
  .flexCartBox {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.cartBox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 10px;
  padding: 15px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.8);
}
.cartBox img {
  height: 150px;
  object-fit: contain;
  margin-left: 15px;
}

@media (min-width: 480px) {
  .cartBox {
    width: 320px;
    margin: 20px auto;
  }
}
@media (min-width: 800px) {
  .cartBox {
    margin: 20px;
  }
}
.footer {
  text-align: center;
  height: 215px;
}

h2 {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 2.5rem;
  margin-top: 35px;
  cursor: pointer;
  margin-top: 35px;
}
h2 a {
  text-decoration: none;
}
h2 a:link {
  color: white;
}
h2 a:visited {
  color: white;
}

h3 {
  font-family: "Raleway";
  margin-top: 15px;
}

.socialLinks {
  margin: 15px;
}

.link {
  margin: 10px;
}
.link img {
  height: 40px;
}

.hero {
  width: 100vw;
  height: 55vh;
  margin-bottom: 200px;
  margin-top: -170px;
}
.hero .heroText {
  position: relative;
  top: 70%;
}
.hero .heroText h1 {
  font-family: "Raleway";
  font-weight: 700;
  font-size: 3rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.49);
}
.hero .heroText h2 {
  font-family: "PT Serif";
  font-size: 1.6rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.49);
}
.hero .videoTag {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (min-width: 800px) {
  .hero {
    margin-top: -200px;
    margin-bottom: 200px;
  }
  .hero .heroText h1 {
    font-size: 4rem;
  }
  .hero .heroText h3 {
    font-size: 1.5rem;
  }
}

/*# sourceMappingURL=style.css.map */

